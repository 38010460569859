@import '_fonts.scss';
@import '_colors.scss';
@import '_variables.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/*#region box shadow*/
* {
  font-size: 16px;
  font-family: 'Graphik Web', 'Source Code Pro', sans-serif, Helvetica, Arial !important;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.mat-tooltip {
  background: rgba(2, 3, 7, 0.8);
}

.cdk-global-scrollblock {
  overflow-y: unset;
}

.sidebar-shadow-right {
  background: #FFFFFF;
  /* Sidebar Shadow Right */
  box-shadow: 8px 4px 32px rgba(32, 45, 74, 0.24), 8px 0px 16px rgba(32, 45, 74, 0.24);
}

.bright-button-shadow {
  background: #FFFFFF;
  /* Bright Button Shadow */
  box-shadow: 4px 4px 8px rgba(0, 22, 255, 0.12), -4px -4px 8px rgba(0, 22, 255, 0.12);
}

.shorter-drop {
  background: #FFFFFF;
  /* Shorter Drop */
  box-shadow: 0px 2px 8px rgba(32, 45, 74, 0.2) !important;
}

.medium-drop {
  background: #FFFFFF;
  /* Medium Drop */
  box-shadow: 0px 4px 16px rgba(32, 45, 74, 0.2);
}

.higher-drop {
  background: #FFFFFF;
  /* Higher Drop */
  box-shadow: 0px 8px 32px rgba(32, 45, 74, 0.2);
}

.negative-shorter-drop {
  background: #FFFFFF;
  /* Negative Shorter Drop */
  box-shadow: 0px -2px 8px rgba(32, 45, 74, 0.2);
}

.negative-medium-drop {
  background: #FFFFFF;
  /* Negative Medium Drop */
  box-shadow: 0px -4px 16px rgba(32, 45, 74, 0.2);
}

.negative-higher-drop {
  background: #FFFFFF;
  /* Negative Higher Drop */
  box-shadow: 0px -8px 32px rgba(32, 45, 74, 0.24);
}

.tabular-data-divider {
  box-shadow: inset 0px -1px 0px #E9E9E9;
}

.neumorphism {
  background: #FFFFFF;
  /* Neumorphism */
  box-shadow: -15px -15px 20px rgba(255, 255, 255, 0.48), -20px -20px 40px rgba(255, 255, 255, 0.24), 15px 15px 30px rgba(32, 45, 74, 0.12), 20px 20px 40px rgba(32, 45, 74, 0.18);
}

.white-sidebar {
  background: #FFFFFF;
  /* Sidebar Shadow Right */
  box-shadow: 8px 0px 16px rgba(32, 45, 74, 0.24);
}

/*#endregion*/

/*#region button*/
button.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background-color: $royal;
  box-shadow: 0px 2px 8px rgba(32, 45, 74, 0.2) !important;
  border-radius: 8px;
  color: $white;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;

  .mat-button-wrapper {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &:hover {
    background-color: #707BEE !important;
    box-shadow: 4px 4px 8px rgba(0, 22, 255, 0.12), -4px -4px 8px rgba(0, 22, 255, 0.12) !important;
  }
}

button.secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background-color: $white;
  border: 0.8px solid #4C5AEA;
  box-sizing: border-box;
  border-radius: 8px;
  color: $royal;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;

  .mat-button-wrapper {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &:hover {
    background-color: rgba(76, 90, 234, 0.2) !important;
  }
}

button.tertiary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background-color: $white;
  border: unset;
  box-sizing: border-box;
  border-radius: 8px;
  color: $royal;

  .mat-button-wrapper {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &:hover {
    background-color: rgba(76, 90, 234, 0.2) !important;
  }
}

button.primary-button:disabled, button.primary-button[disabled], button.secondary-button:disabled, button.secondary-button[disabled], button.tertiary-button:disabled, button.tertiary-button[disabled] {
  background-color: $mercury !important;
  color: $santas !important;
  box-shadow: none !important;
  border: none !important;
}

button.secondary-button:disabled, button.secondary-button[disabled] {
  background-color: $mercury !important;
  color: $santas !important;
  box-shadow: none !important;
  border: 0.5px solid #A2A3AB !important;
}

button.primary-button-smaller, button.secondary-button-smaller, button.tertiary-button-smaller {
  padding: 12px 16px;

  .mat-button-wrapper {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

button.primary-button-caption, button.secondary-button-caption, button.tertiary-button-caption {
  padding: 8px 12px;

  .mat-button-wrapper {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

div.text-button, div.caption-button, div.small-text-button {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 32px;

  .text-button-icon {
    cursor: pointer;
    margin-right: 4px;
    height: 24px;
    width: 24px;

    svg path {
      fill: $royal;
    }
  }

  .text-button-value {
    color: $royal;
    cursor: pointer;
    line-height: 1.5rem;
  }

  &:hover {
    .text-button-icon {
      svg path {
        fill: $hover;
      }
    }

    .text-button-value {
      color: $hover;
    }
  }
}

div.text-button-disabled, div.caption-button-disabled, div.small-text-button-disabled {
  .text-button-icon {
    svg path {
      fill: $santas !important;
    }
  }

  .text-button-value {
    color: $santas !important;
  }
}

button.mat-button__disable-ripple, .mat-icon-button.mat-icon-button__disable-ripple {
  .mat-ripple {
    display: none;
  }

  .mat-button-focus-overlay {
    background-color: transparent;
  }
}

div.caption-button, div.small-text-button {
  height: 24px;

  .text-button-icon {
    height: 16px;
    width: 16px;
  }

  .text-button-value {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

div.small-text-button {
  height: 28px;

  .text-button-value {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media only screen and (max-width: 833px) {
  button.primary-button, button.secondary-button, button.tertiary-button {
    padding: 8px 12px;

    .mat-button-wrapper {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }

  button.primary-button.mobile-no-change, button.secondary-button.mobile-no-change, button.tertiary-button.mobile-no-change {
    padding: 16px 24px;

    .mat-button-wrapper {
      font-size: 1rem !important;
      line-height: 1.25rem !important;
    }
  }
}

@media only screen and (min-width: 833px) and (max-width: 1099px) {
  button.primary-button, button.secondary-button, button.tertiary-button {
    padding: 12px 16px;

    .mat-button-wrapper {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  button.primary-button.mobile-no-change, button.secondary-button.mobile-no-change, button.tertiary-button.mobile-no-change {
    padding: 16px 24px;

    .mat-button-wrapper {
      font-size: 1rem !important;
      line-height: 1.25rem !important;
    }
  }
}

@media only screen and (min-width: 1099px) and (max-width: 1439px) {
  button.primary-button, button.secondary-button, button.tertiary-button {
    padding: 12px 16px;

    .mat-button-wrapper {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  button.primary-button.mobile-no-change, button.secondary-button.mobile-no-change, button.tertiary-button.mobile-no-change {
    padding: 16px 24px;

    .mat-button-wrapper {
      font-size: 1rem !important;
      line-height: 1.25rem !important;
    }
  }
}
/*#endregion*/

/*#region input field*/
div.form-field {
  width: 100%;
  margin: 16px 0px;

  mat-form-field {
    width: 100%;
    margin: 8px 0px 0px 0px;

    input, mat-select, textarea {
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 400;
      margin: 0px;
    }

    textarea {
      resize: none;
    }
  }

  .autocomplete-loading-result {
    transform: translate( 0.6%, -108%);
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0px !important;
  border-radius: 4px;
}

.mat-form-field.mat-form-field-type-mat-input {
  .mat-form-field-wrapper {
    margin: 0px;
    padding-bottom: 0px;

    .mat-form-field-flex {

      .mat-form-field-infix {
        padding: 0px;
        border-top: 0px;
        padding: 10px 0px 10px;

        .mat-form-field-label-wrapper {
          padding-top: 0px;
          top: 0;
        }
      }

      .mat-form-field-suffix, .mat-form-field-prefix {
        top: 0.45rem !important;

        .form-prefix-suffix-button, .mat-icon-button {
          .mat-button-wrapper {
            mat-icon svg path {
              fill: $santas;
            }
          }
        }

        .mat-datepicker-toggle .mat-icon-button {
          .mat-button-wrapper {
            svg path {
              fill: $santas;
            }
          }
        }
      }

      .mat-form-field-outline-thick {
        color: $mercury;
      }
    }

    .mat-form-field-flex:hover {
      background-color: rgba(76, 90, 234, 0.2);
      border-radius: 4px;

      .mat-form-field-infix {
        color: $royal;
      }

      .mat-form-field-suffix, .mat-form-field-prefix {
        .form-prefix-suffix-button, .mat-icon-button {
          .mat-button-wrapper {
            mat-icon svg path {
              fill: $royal;
            }
          }
        }

        .mat-datepicker-toggle .mat-icon-button {
          .mat-button-wrapper {
            svg path {
              fill: $royal;
            }
          }
        }
      }
    }
  }

  .mat-form-field-underline,
  .mat-form-field-subscript-wrapper {
    padding: 0px;
    margin-top: 24px;
  }
}

.mat-form-field.mat-form-field-type-mat-input.mat-focused {
  .mat-form-field-wrapper {

    .mat-form-field-flex {
      .mat-form-field-suffix, .mat-form-field-prefix {
        .form-prefix-suffix-button, .mat-icon-button {
          .mat-button-wrapper {
            mat-icon svg path {
              fill: $royal;
            }
          }
        }

        .mat-datepicker-toggle .mat-icon-button {
          .mat-button-wrapper {
            svg path {
              fill: $royal;
            }
          }
        }
      }

      .mat-form-field-outline-thick {
        color: $royal;
      }
    }

    .mat-form-field-flex:hover {
      background-color: $white;

      .mat-form-field-infix {
        color: $ebony;
      }
    }
  }
}

.mat-form-field.mat-form-field-invalid.mat-form-field-invalid {
  .mat-form-field-wrapper {

    .mat-form-field-flex {
      .mat-form-field-outline-thick {
        color: $crimson;
      }

      .mat-form-field-suffix, .mat-form-field-prefix {
        .form-prefix-suffix-button, .mat-icon-button {
          .mat-button-wrapper {
            mat-icon svg path {
              fill: $crimson;
            }
          }
        }

        .mat-datepicker-toggle .mat-icon-button {
          .mat-button-wrapper {
            svg path {
              fill: $crimson;
            }
          }
        }
      }
    }
  }
}

.mat-form-field.mat-warn .mat-input-element, .mat-form-field-invalid .mat-input-element {
  caret-color: $crimson;
}

.mat-error {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: $crimson !important;
}

.mat-form-field-prefix .mat-icon-button, .mat-form-field-suffix .mat-icon-button {
  width: 24px !important;
  height: 24px !important;
  align-items: unset !important;
}

.field-readonly {
  .mat-form-field.mat-form-field-type-mat-input {
    .mat-form-field-wrapper {

      .mat-form-field-flex {
        background-color: unset;
        padding: 0px;
        border-radius: 0px;
        /*       &:hover {
          background-color: unset;
          border-radius: 0px;
        }*/
        .mat-form-field-infix {
          /*color: $ebony;*/
          /*padding: 8px 0px 8px;*/
        }

        .mat-form-field-outline, .mat-form-field-outline-thick {
          color: white;
          opacity: 0;
        }
      }

      .mat-form-field-flex:hover {
        background-color: unset;
        border-radius: 0px;
      }
    }

    .mat-form-field-underline,
    .mat-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.field-disabled {
  .mat-form-field.mat-form-field-type-mat-input {
    .mat-form-field-wrapper {

      .mat-form-field-flex {
        background-color: $mercury;
        border-radius: 4px;

        .mat-form-field-infix {
          color: $santas;
        }
      }

      .mat-form-field-flex:hover {
        background-color: $mercury;
        border-radius: 4px;

        .mat-form-field-infix {
          color: $santas;
        }
      }
    }

    .mat-form-field-underline,
    .mat-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.mat-calendar-body-selected {
  background-color: $royal;
}

/*#endregion*/

/*#region dropdown field*/

.mat-form-field.mat-form-field-type-mat-select {
  .mat-form-field-wrapper {
    margin: 0px;
    padding: 0px;

    .mat-form-field-flex {

      .mat-form-field-infix {
        padding: 0px;
        border-top: 0px;
        padding: 10px 0px 10px;

        .mat-form-field-label-wrapper {
          padding-top: 0px;
          top: 0;
        }

        .mat-select {
          .mat-select-trigger {
            .mat-select-arrow-wrapper {
              transform: unset;
              display: none;

              .mat-select-arrow {
                color: $santas;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid;
              }
            }
          }
        }
      }

      .mat-form-field-outline-thick {
        color: $mercury;
      }
    }

    .mat-form-field-flex:hover {
      background-color: rgba(76, 90, 234, 0.2);
      border-radius: 4px;

      .mat-form-field-infix {

        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              color: $royal;
            }

            .mat-select-arrow-wrapper {
              .mat-select-arrow {
                color: $royal;
              }
            }
          }
        }
      }
    }
  }

  .mat-form-field-underline,
  .mat-form-field-subscript-wrapper {
    padding: 0px;
    margin-top: 24px;
  }
}

.mat-form-field.mat-form-field-type-mat-select.mat-focused {
  .mat-form-field-wrapper {

    .mat-form-field-flex {

      mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-arrow-wrapper {
              .mat-select-arrow {
                color: $royal;
              }
            }
          }
        }
      }

      .mat-form-field-outline-thick {
        color: $royal;
      }
    }

    .mat-form-field-flex:hover {
      background-color: $white;

      .mat-form-field-infix {
        color: $ebony;
      }
    }
  }
}

.mat-form-field.mat-form-field-type-mat-select.mat-form-field-invalid {
  .mat-form-field-wrapper {

    .mat-form-field-flex {

      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-arrow-wrapper {
              .mat-select-arrow {
                color: $crimson;
              }
            }
          }
        }
      }

      .mat-form-field-outline-thick {
        color: $crimson;
      }
    }
  }
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  color: $royal;
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(76, 90, 234, 0.2);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: $royal !important;
}

/*#endregion*/

/*#region radio button and checkbox field*/

// radio button
.radio-field {
  font-weight: 500;
  font-size: 1rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 20px;
  /*min-width: 150px;*/
  cursor: pointer;
  padding: 8px 12px 8px 20px;
  margin: 8px 0 16px;
  border-radius: 4px;
  border: 1px solid $mercury;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

  input[type="radio"] {
    display: none;

    &:checked ~ .custom-radio::before {
      background: $white;
      content: "✓";
      color: $royal;
      font-size: 11px;
      line-height: normal;
      font-weight: 700;
      text-align: center;
      padding-top: 0px;

      radio-field ~ custom-radio {
        background: $royal;
      }
    }

    &:checked ~ .custom-radio {
      border: none !important;
    }
  }

  &:hover {
    background: rgba(76, 90, 234, 0.20);
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    color: $royal !important;
  }

  .custom-radio {
    border: 1.5px solid $santas;
    border-radius: 100%;
    height: 12px;
    width: 12px;
    margin-right: 16px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    cursor: pointer;

    &::before {
      display: -ms-grid;
      display: grid;
      content: "";
      border-radius: 100%;
      height: 14px;
      width: 14px;
      -webkit-transition: background 0.25s ease-in-out;
      transition: background 0.25s ease-in-out;
    }
  }
}

.radio-field.mat-radio-checked {
  background-color: $royal;
  color: $white;

  &:hover {
    color: $white !important;
  }
}

.single-radio-checkbox-field {
  margin: 8px 0px 0px;
}

.radio-button-checked {
  background: $royal;
  color: $white;
}

.mat-radio-outer-circle {
  height: 10px !important;
  left: 5px !important;
  top: 5px !important;
  width: 10px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $royal !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $white !important;
  content: "✓";
  color: $royal;
}

// checkbox
.checkbox-field {
  font-weight: 500;
  font-size: 1rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 20px;
  cursor: pointer;
  padding: 8px 12px 8px 20px;
  margin: 8px 0 16px;
  border-radius: 4px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

  input[type="checkbox"] {
    display: none;

    &:checked ~ .custom-checkbox::before {
      background: transparent;
      content: "✓";
      color: $royal;
      font-size: 24px;
      line-height: normal;
      font-weight: 700;
      text-align: center;
      padding-top: 0px;
      position: absolute;
      top: -11px;
      right: -3px;
    }

    &:checked ~ .custom-checkbox {
      border: 1.5px solid $royal;
    }
  }

  &:hover {
    background: rgba(76, 90, 234, 0.20);
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    color: $royal !important;

    .custom-checkbox {
      border: 2px solid $royal;
    }
  }

  .custom-checkbox {
    border: 1.5px solid $santas;
    border-radius: 4px;
    height: 14px;
    width: 14px;
    margin-right: 16px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    position: relative;

    &::before {
      -webkit-transition: background 0.25s ease-in-out;
      transition: background 0.25s ease-in-out;
    }
  }
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $royal !important;
}

.mat-checkbox.mat-accent.mat-checkbox-checked,
.mat-checkbox.mat-accent.mat-checkbox-indeterminate {
  .mat-checkbox-background {
    background-color: unset;
    border: 2px solid $santas;
  }

  .mat-checkbox-checkmark {
    height: 21px;
    width: 22px;
    top: -7px;
    left: -2px;
  }
}

.mat-checkbox-layout {
  .mat-checkbox-label {
    font-size: 16px;
    font-weight: 400;
    color: $ebony;
    white-space: normal;
    line-height: 20px;
  }
}

.shyam.mat-checkbox-layout {
  .mat-checkbox-label {
    font-size: 16px;
    font-weight: 400;
    color: #A2A3AB !important;
    white-space: normal;
    line-height: 20px;
  }
}

.mat-checkbox-background, .mat-checkbox-frame {
  border-radius: 6px !important;
  border: 2px solid $santas;
}

.mat-checkbox-inner-container {
  margin-top: 2px !important;
}

.mat-checkbox.mat-accent {
  .mat-checkbox-checkmark-path {
    stroke: $royal !important;
  }
}

.mat-checkbox.mat-accent .mat-ripple-element, .mat-accent .mat-ripple-element, .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-accent .mat-ripple-element {
  background: unset !important;
}

.mat-checkbox.mat-accent .mat-checkbox-inner-circle {
  color: $royal;
}

.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: .16;
}

.mat-checkbox.mat-accent {
  .mat-checkbox-frame {
    border: 1.5px solid $santas;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    border-color: $royal;
  }
}
/*#endregion*/

html, body {
  height: 100%;
}

body {
  margin: 0;
}

body:has(snack-bar-container) {
  margin-top: 104px;

  side-nav-bar > .side-nav-bar {
    margin-top: 104px;
  }
}

/*#region margin styles*/
.m-0 {
  margin: 0;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-14 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-64 {
  margin-top: 64px;
}

.mt-auto {
  margin-top: auto;
}
.mt-0{
    margin-top:0px !important;
}

.ml-4 {
  margin-left: 4px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-26 {
  margin-left: -26px;
}
.mr-2{
    margin-right:2px;
}
/*#endregion*/

/*#region font styles*/
.fw-600 {
  font-weight: 600;
}
/*#endregion*/

/*#region manual created classes */
.container {
  display: flex;
  justify-content: center;
  margin: 0 0 0 250px;

  .container-inner {
    padding: 64px 72px;
    width: 100%;
    max-width: calc(1190px - 144px);
  }

  .heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}

.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
}

@media only screen and (max-width: 833px) {
  .container {
    margin: 0 0 0 0;

    .container-inner {
      padding: 40px 24px;
      box-sizing: border-box;

      .heading {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }

  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: block;
  }
}

@media only screen and (min-width: 833px) and (max-width: 1099px) {
  .container {
    margin: 0 0 0 0;

    .container-inner {
      padding: 40px 24px;
      box-sizing: border-box;

      .heading {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 1099px) and (max-width: 1439px) {
  .container {
    margin: 0 0 0 125px;

    .container-inner {
      .heading {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}

.d-flex {
  display: flex;
}
.d-flex-row{
    display:flex;
    align-items:center;
}

.flex-d-r {
  flex-direction: row;
}

.flex-d-c {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.w-100 {
  width: 100%;
  box-sizing: border-box;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-space-between {
  justify-content: space-between;
}

.not-found {
  color: $white;
  font-size: 64px !important;
  background: $astronaut;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.justify-right {
  justify-content: flex-end;
}

.calinder-toggle-icon {
  margin-top: -16px;
}

.border-color {
  border: 1px solid $mercury;
}

.rotate-reverse {
  transform: rotate(180deg);
}
/*#endregion*/

/*#region Mat Dialog*/
.normal-dialog {
  .mat-dialog-container {
    height: auto;
    max-height: 90vh;
    width: 100%;
    max-width: 720px;
    padding: 40px 56px;
    margin: auto;

    .mat-dialog-title {
      margin-bottom: 32px;
      font-size: 32px;
      line-height: 35px;

      .mat-icon-button {
        top: 0;
      }
    }

    .mat-dialog-actions {
      margin-bottom: 0;
      padding: 0;
    }

    .container {
      position: static;
      padding: 0;
    }
  }
}

.callback-full-screen-dialog {
  .mat-dialog-container {
    height: auto;
    max-height: 90vh;
    width: 720px;
    position: relative;
    display: flex;
    flex-direction: column;
    /*font-family: $primary-font-family;
        font-feature-settings: $font-feature-settings;
        box-shadow: $shorter-drop;*/
    background: $white;
    border-radius: 8px;
    margin: auto;
    padding: 40px 56px;

    .primary-container {
      .header-div {
        display: flex;
        position: relative;
        align-items: center;

        .close-button {
          position: absolute;
          right: 0;
        }

        .header-text {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 32px;
        }
      }

      .content-div {
        color: black;
        font-size: 16px !important;
        font-weight: 400;
        padding-bottom: 32px;
      }

      .content-div-data {
        display: flex;
        justify-content: space-between;
        gap: 32px;

        .item {
          width: 100%;

          .data-header {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 4px;
            width: 40%;
          }

          .data-text {
            font-size: 16px;
            font-weight: 400;
          }
        }

        gap: 32px;
        display: flex;
        color: black;
        font-size: 16px;
        font-weight: 400;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 32px;
      }

      .button-div {
        /*padding-top: 32px;*/
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;

        .cancel-button-div {
          height: 32px;
          display: flex;
          width: auto;
          align-items: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;

          span {
            padding: 4px 4px 4px 0px;
            color: $royal;
            font-weight: 600;
          }
        }

        .yes-button {
          width: 240px !important;
        }
      }
    }

    .mat-dialog-title {
      display: flex;
      align-items: center;
      font: unset;
      /*font-family: $primary-font-family;
            font-feature-settings: $font-feature-settings !important;*/
      font-style: normal;
      margin: 0 0 16px;
      color: $ebony;
      justify-content: start;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;

      .mat-icon-button {
        position: absolute;
        right: 24px;
        top: 24px;
        color: $astronaut;
      }

      .mat-icon-button.back-button {
        right: unset;
        left: 72px;
      }

      .mat-icon-button.close-button {
        right: 72px;
        left: unset;
      }
    }

    .mat-dialog-content {
      max-height: unset;
      overflow: hidden;
      padding: 0;
    }

    .mat-dialog-actions {
      margin-top: 16px;
      margin-bottom: 16px;
      padding: 0;
    }

    .cursor-pointer {
      cursor: pointer !important;
    }

    .royal {
      color: $royal;
    }

    .ebony {
      color: $ebony;
    }

    .santas {
      color: $santas;
    }

    .astronaut {
      color: $astronaut;
    }

    .midgray {
      color: $midgray;
    }

    .container {
      position: static;
      padding: 0;
    }
  }
}


.mat-dialog-container__full-screen {
  .mat-dialog-container {
    height: 100%;
    max-height: unset;
    width: 100vw;
    box-shadow: unset;
    border-radius: unset;
    overflow-x: hidden;
    background-color: $aqua-haze;
    padding: 0;

    .mat-dialog-title {
      position: fixed;
      width: calc(100% - 144px);
      height: 100%;
      max-height: 48px;
      left: 0px;
      top: 0px;
      border-radius: 0px 0px 8px 8px;
      padding: 28px 72px;
      display: flex;
      justify-content: space-between;
      z-index: 6;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
      }

      .mat-icon-button {
        position: absolute;
        right: 72px;
        top: 54px;
        color: $astronaut;
        z-index: 1;
      }

      .mat-icon-button.mat-back-button {
        left: 72px;
        display: flex;
        align-items: center;
      }

      .mat-icon-button.mat-icon__back-button {
        position: absolute;
        left: 72px;
        top: 54px;
        color: $astronaut;

        img {
          height: 28px;
          width: 28px;
        }
      }
    }

    .mat-dialog-content {
      margin: unset;
      padding: unset;
      max-height: unset;
      width: 100%;
      margin-top: 104px;
      padding-top: 64px;

      .dialog-content-container {
        width: 100%;
        max-width: 720px;
        margin: 0 auto;

        div.form-field {
          margin: 32px 0px;
        }

        .smaller {
          margin: 0;
        }

        .heading {
          font-weight: 600;
          font-size: 36px;
          line-height: 44px;
        }

        .mat-card {
          padding: 40px 56px;
          border-radius: 8px;
          margin: 40px 0;

          .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }
        }

        .mat-card.inner-mat-card {
          margin: 0;
        }
      }
    }

    .mat-dialog-content.table-container {
      max-width: 1045px !important;
    }

    .mat-dialog-actions {
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 16px;
      background-color: $aqua-haze;
      max-width: 720px;
      margin: 0 auto;
      padding: 0;

      .primary-button {
        width: 240px;
      }
    }

    .mat-dialog-content.min-height {
      min-height: calc(75vh - 90px);
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 2, 25, 0.56);
}

@media only screen and (max-width: 833px) {
  .mat-dialog-container__full-screen {
    .mat-dialog-container {
      background-color: $white;

      .mat-dialog-content {
        .dialog-content-container {
          max-width: 608px;
          box-sizing: border-box;
          padding: 0 24px;

          .mat-card {
            box-shadow: unset !important;
            padding: 0;
          }
        }
      }

      .mat-dialog-actions {
        background-color: $white;
        max-width: 608px;
        box-sizing: border-box;
        padding: 0 24px;
      }

      .mat-dialog-title {
        width: calc(100% - 48px);
        padding: 28px 24px;

        .wordmark-img {
          width: 150px;
          height: 18.1px;
        }

        .close-icon {
          height: 24px;
        }
      }
    }
  }
}

@media only screen and (min-width: 833px) and (max-width: 1099px) {
  .mat-dialog-container__full-screen {
    .mat-dialog-title {
      width: calc(100% - 64px);
      padding: 28px 32px;

      .wordmark-img {
        width: 150px;
        height: 18.1px;
      }

      .close-icon {
        height: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1099px) and (max-width: 1439px) {
  .mat-dialog-container__full-screen {
    .mat-dialog-title {
      width: calc(100% - 80px);
      padding: 28px 40px;

      .wordmark-img {
        width: 175px;
        height: 21.12px;
      }
    }
  }
}

/*#endregion*/

/*#region mat progress bar*/
//it is not working in template css
.mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element::after {
  background-color: $royal;
  border-top-right-radius: 30% 10px;
  border-bottom-right-radius: 30% 10px;
  border-top-left-radius: 35% 10px;
  border-bottom-left-radius: 35% 10px;
}

.mat-progress-bar-buffer {
  background-color: $mercury;
}
/*#endregion*/

/*#region mat-snack bar*/
.cdk-global-overlay-wrapper {
}

.cdk-overlay-pane:has(snack-bar-container) {
  width: 100%;
}

.cdk-overlay-pane {
  .mat-snack-bar-container {
    background: none;
    max-width: 100%;
    min-width: 100%;
    margin: 0;
    color: unset;
    box-shadow: unset;
  }

  .mat-snack-bar-container.top-banner-default {
    background: transparent !important;
    width: 100% !important;
    padding: 0px !important;
    height: 104px !important;
  }

  .mat-snack-bar-container.transparent-bottom-default {
    background: none !important;
    max-width: 365px !important;
    min-width: 265px !important;
    padding: 10px 15px !important;
    margin-bottom: 32px !important;

    .mat-simple-snackbar {
      color: $white;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      justify-content: flex-start;

      .mat-simple-snackbar-action {
        margin-left: 20px;

        .mat-button-wrapper {
          color: #7A81BE;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0px;
        }
      }
    }
  }
}

.mat-snack-bar-handset {
  .mat-snack-bar-container {
    margin: 0 !important;
  }
}

/*#endregion*/

/*#region mat paginator*/
.mat-form-field.mat-paginator-page-size-select {
  line-height: 1;

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-select {
          .mat-select-trigger {
            .mat-select-arrow-wrapper {
              .mat-select-arrow {
                border-bottom-right-radius: 4px;
              }
            }
          }
        }
      }

      .mat-form-field-outline-thick {
        color: $mercury;
      }
    }

    .mat-form-field-flex:hover {
      background-color: rgba(76, 90, 234, 0.2);
      border-radius: 8px;

      .mat-form-field-infix {

        .mat-select {
          .mat-select-trigger {
            .mat-select-value {
              color: $royal;
            }

            .mat-select-arrow-wrapper {
              .mat-select-arrow {
                color: $royal;
              }
            }
          }
        }
      }
    }
  }
}

.mat-paginator-page-size-select {
  width: 75px !important;
  height: 32px;
  margin: 0 0 0 4px !important;

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      background: $white;
      border: 0.5px solid $royal;
      border-radius: 8px;

      .mat-form-field-infix {
        padding: 8px 12px 8px !important;

        .mat-select {
          .mat-select-trigger {
            .mat-select-arrow-wrapper {
              transform: unset;

              .mat-select-arrow {
                color: $royal !important;
                border-top: 8px solid;
                border-right: none !important;
                border-style: solid !important;
                border-width: 0 2px 2px 0 !important;
                padding: 5px !important;
                transform: rotate(-315deg) !important;
                transform: matrix(1, 0, 0, -1, 0, 0);
                margin-bottom: 5px;
              }
            }
          }
        }

        .mat-select-value-text {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: $royal;
        }
      }
    }

    .mat-form-field-underline {
      display: none !important;
    }
  }
}

.mat-paginator-container {
  justify-content: space-between !important;
  padding: 16px 32px !important;
  height: 32px;
  min-height: 32px;

  .mat-paginator-page-size {
    .mat-paginator-page-size-label {
      visibility: hidden;
      position: relative;
      margin: 0 0px;
    }

    .mat-paginator-page-size-label::after {
      visibility: visible;
      content: 'Rows per page';
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5E5F68;
    }

    .mat-paginator-page-size-select {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }
  }

  .mat-paginator-range-actions {
    .mat-paginator-range-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5E5F68;
    }

    .mat-paginator-range-label::before {
      content: 'Page';
    }

    .mat-icon-button {
      background: #FFFFFF;
      border: 0.5px solid $royal;
      border-radius: 8px;
      margin: 0 4px;
      color: $royal !important;
      height: 32px;
      width: 32px;

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        height: 16px;
        width: 16px;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (max-width: 833px) {
  .mat-paginator-container {
    .mat-paginator-page-size {
      .mat-paginator-page-size-label {
        display: none;
      }
    }

    .mat-paginator-range-actions {
      .mat-paginator-range-label {
        font-size: 12px;
        line-height: 16px;
        margin: 0 8px 0 24px;
      }
    }
  }
}
/*#endregion*/
/*#region mat-table*/
.mat-data-table {
  border-radius: 8px;

  table.mat-table {
    background-color: transparent;
    box-shadow: inset 0px -2px 0px $mercury;
    border-radius: 8px 8px 0px 0px;

    .mat-header-row {
      height: 64px;
      box-shadow: inset 0px -2px 0px $mercury;

      th {
        padding: 8px 32px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        padding-top: 32px;
        border-bottom: 2px solid $mercury;
      }
    }

    .mat-row {
      height: 52px;
      box-shadow: inset 0px -1px 0px $mercury;

      td.mat-cell {
        padding: 16px 32px;
        border-color: $mercury;
      }

      td.mat-cell.icon-cell {
        display: flex;
        justify-content: flex-end;
      }
    }

    .mat-row:nth-child(even) {
      background-color: #F8FBFD;
    }

    .mat-row:hover {
      background-color: rgba(76, 90, 234, 0.2);
    }
  }

  .mat-paginator {
    border-radius: 8px;

    .mat-paginator-outer-container {
    }
  }

  .search--filter-container {
    padding: 32px;
    display: grid;
    grid-template-columns: 5fr 1fr;

    .search-container {
      max-width: 342px;
    }

    .filter-container {
      display: grid;
      grid-template-columns: 4fr 1fr;

      img {
        margin-right: 5px;
      }
    }
  }
}
/*#endregion*/
/*#region mat-card*/
.mat-card {
  color: $ebony;
  border-radius: 8px !important;
}
/*#endregion*/
/*#region mat tab group*/

.mat-tab-group {
  width: 100%;

  .mat-tab-label-container {
    .mat-tab-list {
      .mat-tab-labels {
        .mat-tab-label {
          opacity: 1 !important;
          color: $ebony;
          height: 24px !important;
          padding: 0;
          margin-right: 48px;
          justify-content: flex-start;
          min-width: auto;

          .mat-tab-label-content {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .mat-tab-label.mat-tab-label-active {
        padding: 0px;

        .mat-tab-label-content {
          color: $royal;
          font-weight: 600;
        }
      }

      .mat-link-bar {
        background-color: $royal;
        border-radius: 8px 8px 0 0;
      }
    }
  }
}

/*#endregion*/

/*#region mat menu*/
.mat-menu-panel {
  border-radius: 8px !important;
  box-shadow: 0px 8px 32px rgba(32, 45, 74, 0.2);
}

.mat-menu-panel.forms-panel {
  /*margin-top: 8px;*/
  //min-width: 250px !important;
  max-width: 100% !important;
  width: 412px !important;
  padding: 16px !important;
  box-shadow: 0px 8px 32px rgb(32 45 74 / 20%);
  border-radius: 8px;
}

.mat-menu-content:not(:empty) {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.mat-menu-item {
  color: $ebony;
  line-height: 20px !important;
  height: 40px !important;
  margin-bottom: 0.25rem;
  min-width: 253px;
}

.mat-menu-item:last-child {
  margin-bottom: 0;
}


.mat-stepper-vertical, .mat-stepper-horizontal {
  background-color: none !important;
}

.mat-stepper-vertical, .mat-stepper-horizontal.without-header-stapper {
  .mat-horizontal-stepper-header-container {
    display: none;
  }
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  // margin-top: -1.25em !important;
}

.wd-240 {
  width: 240px;
}
/*Intake*/
/*.upper-button {
  display: flex;
  align-items: center;

  .text {
    font-weight: 600;
    font-size: 16px;
    color: #4C5AEA;
    margin-left: 3px;
  }
}

.main__container {
  margin-top: 141px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;

  .computerIntake {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sub__container {
      padding: 2px 4px;
      width: 720px;

      .main_heading_top {
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
      }

      .stepicon {
        height: 8px !important;
        width: 11px !important;
      }

      .main__dialog {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 56px;
        gap: 32px;
        width: 720px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(32, 45, 74, 0.2);
        border-radius: 8px;
        box-sizing: border-box;

        .heading_color {
          color: #272E78;
        }

        .field__container {
          width: 608px;

          .form-field {
            &.mtop {
              margin-top: 32px !important;
            }

            &.notop {
              margin-top: 0px !important;
            }

            &.form_field_devider {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            .upload {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 32px;
              gap: 16px;
              width: 608px;
              height: 200px;
              justify-content: center;

              .card_icon {
                height: 40px;
                width: 32px;
              }
            }

            .card-content {
              text-align: center;
            }

            .inputfile {
              display: none;
            }

            .main_heading {
              font-weight: 600;
              color: #272E78;
            }

            .radio_text {
              text-align: right;
              font-size: 12px;
            }

            .text_card {
              display: flex;
              flex-direction: column;
              background: #E9E9E9;
              border-radius: 8px;
              padding: 15px;
              color: #020307;
              text-align: left;
            }

            .text_area {
              width: 608px;
              height: 180px;
              border: 1px solid #E9E9E9;
              border-radius: 8px;
            }

            .field_divider {
              gap: 10px;
              width: 47%;

              .message {
                float: right;
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }

      .button {
        margin-top: 40px;
        display: flex;
        justify-content: end;


        &.button_back {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .back {
          border: hidden;
          background: unset !important;
          color: #4C5AEA;
          display: flex;
          font-weight: 600;
          align-items: center;

          .icon {
            margin-right: 10px;
          }
        }

        .button_primary {
          width: 240px;
          height: 52px;
          left: 840px;
          top: 500px;
          background: #4C5AEA;
          box-shadow: 0px 2px 8px rgba(32, 45, 74, 0.2);
          border-radius: 8px;
          border: unset !important;
          color: white;
        }

        .text-button {
          .text-button-icon {
            line-height: 16px;
          }

          .text-button-value {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.mat-stepper-horizontal.computerIntake, .mat-stepper-vertical.computerIntake {
  background-color: unset;
}*/
/*Intake*/
/*Intake Region*/
.upper-button {
  display: flex;
  align-items: center;

  .text {
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #4C5AEA !important;
    margin-left: 3px !important;
  }
}

.main__container {
  margin-top: 141px;
  display: flex;
  justify-content: center;
  width: 100%;

  .sub__container {
    padding: 2px 4px;

    .main_heading_top {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
    }

    .stepicon {
      height: 8px !important;
      width: 11px !important;
    }

    .main__dialog {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px 56px;
      gap: 32px;
      width: 720px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px rgba(32, 45, 74, 0.2);
      border-radius: 8px;
      box-sizing: border-box;

      .heading_color {
        color: #272E78;
      }

      .field__container {
        width: 608px;

        .form-field {
          &.mtop {
            margin-top: 32px !important;
          }

          &.notop {
            margin-top: 0px !important;
          }

          &.form_field_devider {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .with_minus_icon {
            display: flex;
            flex-direction: row;
            margin: 0px;
            margin-bottom: -18px;
            justify-content: space-between;

            .icon_type {
              display: flex;
              color: blue;
            }
          }

          .upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 32px;
            gap: 16px;
           // width: 608px;
            height: 200px;
            justify-content: center;

            .card_icon {
              height: 40px;
              width: 32px;
            }
          }

          .card-content {
            text-align: center;
          }

          .inputfile {
            display: none;
          }

          .main_heading {
            font-weight: 600;
            color: #272E78;
          }

          .radio_text {
            text-align: right;
            font-size: 12px;
          }

          .text_card {
            display: flex;
            flex-direction: column;
            background: #E9E9E9;
            border-radius: 8px;
            padding: 15px;
            color: #020307;
            text-align: left;
          }

          .text_area {
            width: 608px;
            height: 180px;
            border: 1px solid #E9E9E9;
            border-radius: 8px;
            padding:10px;
          }

          .field_divider {
            gap: 10px;
            width: 47%;

            .message {
              float: right;
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }
    }

    .button {
      margin-top: 40px;
      display: flex;
      justify-content: end;


      &.button_back {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .back {
        border: hidden;
        background: unset !important;
        color: #4C5AEA;
        display: flex;
        font-weight: 600;
        align-items: center;

        .icon {
          margin-right: 10px;
        }
      }

      .button_primary {
        width: 240px;
        height: 52px;
        left: 840px;
        top: 500px;
        background: #4C5AEA;
        box-shadow: 0px 2px 8px rgba(32, 45, 74, 0.2);
        border-radius: 8px;
        border: unset !important;
        color: white;
      }

      .text-button {
        .text-button-icon {
          line-height: 16px;
        }

        .text-button-value {
          font-weight: 600;
        }
      }
    }
  }
}

.mat-stepper-horizontal.computerIntake, .mat-stepper-vertical.computerIntake {
  background-color: unset;
}

.radio-down-text {
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  margin-top: -14px !important;
}
.radio-down-top{
    margin-top:-5px !important;
}

.section_heading {
  font-weight: 600;
  font-size: 16px;
  color: #272E78;
  line-height: 20px;
}
/*Intake Region*/

.mat-stepper-vertical, .mat-stepper-horizontal {
  background-color: none !important;
}

.mat-stepper-vertical, .mat-stepper-horizontal.without-header-stapper {
  .mat-horizontal-stepper-header-container {
    display: none;
  }
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  // margin-top: -1.25em !important;
}

.wd-240 {
  width: 240px;
}

.section_heading_save {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #4C5AEA !important;
}

.section_heading {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 20px;
  color: #272E78;
}

.mat-radio-label-content


{ white-space: normal; }

.mat-radio-down-text {
  margin-top: -14px;
}

.align_center_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/*#endregion*/
/*#region full calendar css*/
.full-calendar.fc {
  max-width: 780px;
  height: 100vh;
  border: unset;

  .fc-header-toolbar.fc-toolbar {
    border-radius: 8px 8px 0px 0px;
    border: 1px solid $mercury;
    margin-bottom: 0;
    padding: 24px 32px;
    position: relative;
    height: 72px;

    .fc-toolbar-chunk {
      .fc-today-button {
        display: none;
      }

      .fc-toolbar-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $ebony;
        position: absolute;
        top: 24px;
        left: 72px;
      }

      .fc-prev-button {
      }

      .fc-next-button {
      }

      .fc-button {
        background: transparent;
        color: $royal;
        border: transparent;
        padding: 0;
        height: 24px;
      }

      .fc-button-group {
        position: absolute;
        left: 32px;
        top: 24px;
        margin: 0;
        gap: 190px;
      }
    }
  }

  .fc-view-harness {
    .fc-view {
      .fc-scrollgrid.fc-scrollgrid-liquid {
        //border: 1px solid #e9e9e9;
        border: unset;
        border-radius: 8px;
        background: white;

        thead {
          tr {
            th {
              height: 46px;
              width: 109px;
              border: none;
              background: $whisper;
              color: $midgray;

              .fc-scrollgrid-sync-inner {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .fc-col-header-cell-cushion {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }

            th:first-child {
              border-left: 1px solid $mercury;
            }

            th:last-child {
              border-right: 1px solid $mercury;
            }
          }
        }

        tbody {
          height: 100vh;
          max-height: 500px;

          tr {
            td {
              width: 109px;
              height: 100px;
              background: $white;
              border: 1px solid $mercury;
              border-top: unset;

              .fc-daygrid-day-top {
                justify-content: flex-end;

                .fc-daygrid-day-number {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: $midgray;
                  margin-left: 5px;
                  margin-top: 4px;
                  margin-bottom: 4px;
                }
              }

              .fc-daygrid-day-events {
                .fc-daygrid-event-harness {
                  .fc-daygrid-event {
                    border: 0;
                    background: $whisper;
                    border-radius: 2px 3px 3px 1px;

                    .fc-event-main {
                      margin-bottom: 4px;

                      .fc-event-title-container {
                        .fc-event-title {
                          white-space: break-spaces;
                          word-break: break-word;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 16px;
                          color: $ebony;
                          padding: 5px 2px 5px 5px;
                          border-left: 3px solid $royal;
                          border-radius: 2px 0px 0px 2px;
                        }
                      }
                    }
                  }
                }
              }

              .fc-scroller-harness {
                .fc-scroller-liquid-absolute {
                  border-radius: 0px 0px 8px 8px;
                  box-sizing: unset;
                }
              }

              .fc-daygrid-day:nth-last-child(1) {
                border-right-color: transparent;
              }
            }

            td.fc-day-today {
              .fc-daygrid-day-top {
                .fc-daygrid-day-number {
                  color: $white;
                  height: 30px;
                  width: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: $royal;
                  border-radius: 8px;
                  margin-left: 6px;
                  margin-top: 4px;
                  margin-bottom: 8px;
                }
              }
            }

            td.fc-day-other {
              background: $mercury;
              border-color: $white;
            }
          }

          .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
            td:first-child {
              border-radius: 0px 0px 8px 8px;
            }

            td.fc-daygrid-day:first-child {
              border-radius: unset;
            }
          }
        }
      }
    }
  }

  .fc-scroller-harness-liquid {
    height: 100vh;
  }
}

.fc-theme-standard td {
  border: 1px solid $mercury;
}

.align_row {
  display: flex;
  /* width: 100%; */
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
}

.lh-20 {
  height: 20px !important;
}

.final_submit_button {
  width: 170px;
  height: 60px;
  float: right;
}
.radio_sub_text {
  font-size: 12px;
  font-weight: 400;
  color:#5E5F68;
  margin-top:0px !important;
}
.big_box {
  height: 180px;
  border-radius: 8px;
}
.float_right{
    float:right;
}
.plan_aggrement_signature_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #020307;
}
.float_right{
    float:right;
}
.mat-error{
    color:red;
}
.cut_image {
  height: 34px !important;
  margin-left: 30px;
}
.padding_down.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0px 0px 13px 0px !important;
}
.disable_color {
  color: #A2A3AB !important;
}
.field_subText {
  color: #5E5F68;
  font-size: 12px;
  font-weight: 400;
  /* margin-top: 24px; */
  margin-top: 2px;
}

.text-center{
    text-align:right;
}
.fs-12{
    font-size:12px !important;
}
.text_transeform_capatalize{
    text-transform:capitalize;
}
.dollar-sign {
  position: absolute;
  left: -9px; /* Adjust the left position as needed */
  top: 50%;
  transform: translateY(-50%);
  color: #333;
}
.pd-l-3{
    padding-left:3px !important;
}

.cookies-dialog {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position:fixed;
  bottom:15px;
  z-index:3000;
}

/*#endregion*/
